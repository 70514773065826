import Button from '@atlaskit/button';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import api from '~/services/api';

import { AlertBox, DataList } from './styles';

interface IFormData {
  text: string | any;
  option: { label: string; value: number | string } | any;
}
type TipoPessoa = 'Cliente' | 'Fornecedor' | 'Loja';
interface Options {
  label: string | undefined;
  value: number | undefined | string;
}
interface IParceiros {
  value: Options | any;
  tipos: TipoPessoa[];
  isRequired: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  setInvalid?: boolean;
  clearOptions?: boolean;
  iniInicializado: boolean;
  invalidLabel?: string;
  onChange(data: any, data1: boolean): any;
}
const Parceiros: React.FC<IParceiros> = (props) => {
  const {
    value = undefined,
    iniInicializado,
    tipos = ['Cliente', 'Fornecedor', 'Loja'],
    isRequired = false,
    setInvalid = false,
    clearOptions = false,
    invalidLabel = undefined,
    placeholder,
    isDisabled,
    onChange,
  } = props;
  const [isInvalid, setIsInvalid] = useState(false);
  const [inicializado, setInicializado] = useState(false);
  const [showOption, setShowOptions] = useState(false);
  const [filtroInicial, setFiltroInicial] = useState('');
  const [resultadoArmazenado, setResultadoArmazenado] = useState([]);
  const [formData, setFormData] = useState<IFormData>({
    text: '',
    option: value,
  });
  const [options, setOptions] = useState<any[]>([]);
  const [defaultLabelText, setDefaultLabelText] = useState(``);

  useEffect(() => {
    if (clearOptions && formData.text === '') {
      setOptions([]);
    }
  }, [clearOptions, formData.text]);

  useEffect(() => {
    (async () => {
      if (inicializado === false) {
        if (value?.value === undefined) return;

        setFormData({
          ...formData,
          option: { label: value?.label, value: value?.value },
          text: value?.label !== undefined ? value.label : '',
        });

        const { data } = await api.get(`/pessoas/cod_pessoa/${value?.value}`);
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const isNullCampo = function (campo: any) {
              if (campo === null) {
                return campo;
              }
              return campo?.toUpperCase();
            };

            const pessoaData = [
              {
                label: `${data.data?.nome_pessoa} (${
                  isNullCampo(data.data?.des_cidade) &&
                  isNullCampo(data.data?.des_cidade)
                } - ${
                  data.data.des_sigla && isNullCampo(data.data.des_sigla)
                })`,
                value: data.data.num_cpf_cnpj,
                apelido_fantasia: data.data.apelido_fantasia,
                cod_pessoa: data.data.cod_pessoa,
                des_uf: data.data.des_uf,
                des_cidade: isNullCampo(data.data.des_cidade),
              },
            ];
            setOptions(pessoaData);
          }
        }
      }
    })();
  }, []);
  const verifyTipos = useCallback(() => {
    const validaTipos = {
      cliente: false,
      fornecedor: false,
      loja: false,
    };
    if (tipos.indexOf('Cliente') !== -1) {
      validaTipos.cliente = true;
    }
    if (tipos.indexOf('Fornecedor') !== -1) {
      validaTipos.fornecedor = true;
    }
    if (tipos.indexOf('Loja') !== -1) {
      validaTipos.loja = true;
    }
    return validaTipos;
  }, [tipos]);

  const isNullCampo = function (campo: any) {
    if (campo === null) {
      return campo;
    }
    return campo?.toUpperCase();
  };

  const handleFilterOptions = useCallback(
    async (filter: string) => {
      filter = filter.toUpperCase();
      setFormData({ ...formData, text: filter });
      if (filter.length === 3) setFiltroInicial(filter);
      if (filter.length >= 3) {
        let lista: any[] = [];
        if (filter.substring(0, 3) === filtroInicial) {
          lista = resultadoArmazenado;
        }
        if (lista.length <= 0) {
          const tiposBusca = await verifyTipos();
          const { data } = await api.get('/parceiros', {
            params: {
              filter,
              tiposDeBusca: tiposBusca,
            },
          });

          const regAtivo = data.data.filter((f: any) => f.flg_inativo !== true);

          lista = regAtivo;
          setResultadoArmazenado(regAtivo);
        } else {
          lista = lista.filter((parceiro: any) => {
            return (
              (parceiro.apelido_fantasia !== null &&
                parceiro.apelido_fantasia.includes(filter)) ||
              (parceiro.nome_pessoa !== null &&
                parceiro.nome_pessoa.includes(filter)) ||
              (parceiro.num_cpf_cnpj !== null &&
                parceiro.num_cpf_cnpj.includes(filter))
            );
          });
        }
        if (lista) {
          const parceiros = lista.map((parceiro) => {
            return {
              // eslint-disable-next-line prettier/prettier
              label: `${parceiro.nome_pessoa}${
                parceiro.des_cidade !== null
                  ? `  (${parceiro.des_cidade} - `
                  : ''
              }${parceiro.des_cidade !== null ? `${parceiro.des_uf})` : ''}`,
              num_cpf_cnpj: parceiro.num_cpf_cnpj,
              value: parceiro.cod_pessoa,
              apelido_fantasia: parceiro.apelido_fantasia,
              cod_pessoa: parceiro.cod_pessoa,
              des_uf: parceiro.des_uf,
            };
          });
          setOptions(parceiros);
        } else {
          setFiltroInicial('');
          setResultadoArmazenado([]);
          setOptions([]);
        }
      }
      if (filter.length < 3) {
        setFiltroInicial('');
        setResultadoArmazenado([]);
        setOptions([]);
      }
      if (filter === '' || filter === undefined) {
        setInicializado(true);
        onChange(
          { label: undefined, value: undefined, des_uf: undefined },
          true,
        );
      }
    },
    [
      filtroInicial,
      formData,
      onChange,
      resultadoArmazenado,
      tipos,
      verifyTipos,
    ],
  );
  const handleOption = useCallback(
    (val: {
      label: string;
      value: string;
      des_uf: string;
      cod_pessoa: number;
    }) => {
      if (isRequired) {
        if (
          val.label === undefined ||
          val.label === '' ||
          val.value === undefined
        ) {
          setIsInvalid(true);
          onChange(
            { label: val.label, value: val.cod_pessoa, des_uf: '' },
            true,
          );
          setDefaultLabelText(`Selecione um parceiro`);
          return;
        }
      }
      setFormData({
        ...formData,
        option: { label: val.label, value: val.value },
        text: val.label,
      });
      onChange(
        { label: val.label, value: val.cod_pessoa, des_uf: val.des_uf },
        false,
      );
      setShowOptions(false);
    },
    [formData, isRequired, onChange],
  );
  const renderDataList = () => {
    if (options && options.length > 0) {
      return options.map((item) => {
        let cgc = '';
        if (item?.num_cpf_cnpj) {
          if (item?.num_cpf_cnpj?.length === 11) {
            cgc = item.num_cpf_cnpj.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/g,
              '$1.$2.$3-$4',
            );
          } else {
            cgc = item?.num_cpf_cnpj?.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              '$1.$2.$3/$4-$5',
            );
          }
        }
        return item.value ? (
          <div
            // style={{
            //   zIndex: 99999,
            // }}
            key={item.value}
            onClick={() =>
              handleOption({
                label: item.label,
                value: item.value,
                des_uf: item.des_uf,
                cod_pessoa: item.cod_pessoa,
              })
            }
          >
            {cgc && <b>{cgc}</b>}
            <br />
            {item.label}
          </div>
        ) : (
          <div
            // style={{
            //   zIndex: 99999,
            // }}
            key={value.value}
            onClick={() =>
              handleOption({
                label: value.label,
                value: value.value,
                des_uf: value.des_uf,
                cod_pessoa: value.cod_pessoa,
              })
            }
          >
            <b>{cgc}</b>
            <br />
            {value.label}
          </div>
        );
      });
    }
    if (formData.text.trim() === '' || formData.text.trim().length < 3) {
      return (
        <div>
          <div
            style={{
              margin: 'auto',
              left: '50%',
              textAlign: 'center',
              color: '#bdbdbd',
              // zIndex: 99999,
            }}
          >
            Digite para pesquisar
          </div>
        </div>
      );
    }
    return (
      <div>
        <div
          style={{
            margin: 'auto',
            left: '50%',
            textAlign: 'center',
            color: '#bdbdbd',
            // zIndex: 99999,
          }}
        >
          Nenhum registro encontrado
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (value.label === undefined || value.value === undefined) {
      setFormData({
        text: '',
        option: value,
      });
    }
  }, [value]);
  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);
  const renderInput = (): React.ReactElement => {
    if (inicializado && isInvalid) {
      return (
        <AlertBox>
          <p>{invalidLabel !== undefined ? invalidLabel : defaultLabelText}</p>
          <Field label="Parceiro" name="Buscar parceiro">
            {({ fieldProps }) => (
              <div style={{ position: 'relative' }}>
                <Textfield
                  {...fieldProps}
                  list="parceiros"
                  type="text"
                  value={formData.text}
                  isDisabled={isDisabled}
                  placeholder={
                    placeholder ||
                    `Informe pelo menos 3 caracteres do ${tipos.join('/')}`
                  }
                  autoComplete="off"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleFilterOptions(e.target.value)
                  }
                  onClick={() => setShowOptions(!showOption)}
                  style={{ cursor: 'pointer' }}
                  autoFocus={inicializado}
                />
                <Button
                  type="button"
                  className="inputButton"
                  style={{
                    float: 'right',
                    marginTop: '-40px',
                    height: '40px',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                  }}
                  tabIndex={-1}
                >
                  <MdKeyboardArrowDown size={16} />
                </Button>
                {showOption && (
                  <>
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      aria-label="closeOptions"
                      onClick={() => setShowOptions(false)}
                    />
                    <DataList
                      style={{
                        zIndex: 99,
                        maxHeight: '300px',
                        overflow: 'auto',
                      }}
                    >
                      {renderDataList()}
                    </DataList>
                  </>
                )}
              </div>
            )}
          </Field>
        </AlertBox>
      );
    }
    return (
      <Field label="Parceiro" name="Buscar parceiro">
        {({ fieldProps }) => (
          <div className="parceiroContainer" style={{ position: 'relative' }}>
            <Textfield
              {...fieldProps}
              list="parceiros"
              type="text"
              value={formData.text}
              isDisabled={isDisabled}
              placeholder={placeholder || `Selecione ${tipos.join('/')}`}
              autoComplete="off"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleFilterOptions(e.target.value);
              }}
              onClick={() => setShowOptions(!showOption)}
              style={{ cursor: 'pointer' }}
              autoFocus={inicializado}
            />
            <Button
              type="button"
              className="inputButton"
              style={{
                float: 'right',
                marginTop: '-40px',
                height: '40px',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
              tabIndex={-1}
            >
              <MdKeyboardArrowDown size={16} />
            </Button>
            {showOption && (
              <>
                <div
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                  aria-label="closeOptions"
                  onClick={() => setShowOptions(false)}
                />
                <DataList
                  style={{
                    zIndex: 99,
                    maxHeight: '300px',
                    overflow: 'auto',
                  }}
                >
                  {renderDataList()}
                </DataList>
              </>
            )}
          </div>
        )}
      </Field>
    );
  };
  // useEffect(() => {
  //   if (inicializado === false) {
  //     setFormData({
  //       ...formData,
  //       option: { label: value?.label, value: value?.value },
  //       text: value?.label !== undefined ? value.label : '',
  //     });
  //     const { data } = await api.get(`/pessoas/cod_pessoa/${value?.value}`);
  //   }
  // }, [formData, inicializado, value]);
  useEffect(() => {
    (async () => {
      if (inicializado === false) {
        setFormData({
          ...formData,
          option: { label: value?.label, value: value?.value },
          text: value?.label !== undefined ? value.label : '',
        });

        if (value?.value === undefined) return;

        const { data } = await api.get(`/pessoas/cod_pessoa/${value?.value}`);
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const pessoaData = [
              {
                label: `${data.data.nome_pessoa} (${
                  isNullCampo(data.data.des_cidade) &&
                  isNullCampo(data.data.des_cidade)
                } - ${
                  data.data.des_sigla && isNullCampo(data.data.des_sigla)
                })`,
                value: data.data.num_cpf_cnpj,
                apelido_fantasia: data.data.apelido_fantasia,
                cod_pessoa: data.data.cod_pessoa,
                des_uf: data.data.des_uf,
                des_cidade: isNullCampo(data.data.des_cidade),
              },
            ];
            setOptions(pessoaData);
          }
        }
      }
    })();
  }, []);
  return <>{renderInput()}</>;
};
export default Parceiros;
